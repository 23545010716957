/* eslint-disable no-console */
export default {
  pushUserId: (user_id, mobile) => {
    window.dataLayer?.push({ user_id, mobile });
  },

  pushPageView: (pageUrl, pageTitle) => {
    window.dataLayer?.push({
      pageType: 'PageView',
      pageUrl,
      pageTitle,
      event: 'pt-tools'
    });
  },

  pushDashboard: (tool, farmId, cropId, action = 'card-show-detail-click') => {
    window.dataLayer?.push({
      action,
      tool,
      farmId,
      cropId,
      event: 'pt-tools-dashboard'
    });
  }
};
