import {
  BrowserCacheLocation,
  PublicClientApplication,
  InteractionRequiredAuthError
} from '@azure/msal-browser';
import { events } from '@pt/utilities';
import { env, enums } from '@pt/env-config';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

const scopes = ['openid', 'offline_access', env.APP_MS_EXPOSE_API];

const msalConfig = {
  auth: {
    clientId: env.APP_MS_CLIENT_ID,
    authority: env.APP_MS_AUTHORITY_SIGNIN,
    knownAuthorities: env.APP_MS_KNOWN_AUTHORITIES.split(','),
    redirectUri: env.APP_MS_REDIRECT_URI
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE || isEdge || isFirefox // Set this to "true" if you are having issues on IE11 or Edge or Firefox
  }
};

let accessToken = null;

const msalInstance = new PublicClientApplication(msalConfig);

const initialize = async () => {
  await msalInstance.initialize();
  // Register Callbacks for Redirect flow
  msalInstance
    .handleRedirectPromise()
    .then(handleRedirectResponse)
    .catch(error => {
      const { errorCode, errorMessage } = error;
      if (errorCode === 'access_denied') {
        if (errorMessage.includes('AADB2C90118')) {
          passwordReset();
        }
        // The user has canceled the entry of information affirmed by himself
        else if (errorMessage.includes('AADB2C90091')) {
          events.emit(enums.EVT_AUTH_USER_CANCELED_ENTRY);
        }
      }
      setLoadingAuth(false);
    });
};

const handleRedirectResponse = resp => {
  const isInIframe = window.parent !== window;
  if (!isInIframe) {
    if (resp !== null) {
      const { idTokenClaims } = resp;
      const policy = idTokenClaims.acr.toUpperCase();
      if (policy === 'B2C_1A_SIGNIN') {
        handleResponse(resp);
      } else {
        handlePolicyChange(policy);
      }
    } else {
      ssoSilent();
    }
  }
};

const setAccount = async account => {
  // After a successful login set the active account to be the user that just logged in
  msalInstance.setActiveAccount(account);
  // Dispatch event in action authenticated user
  events.emit(enums.EVT_AUTH_AUTHENTICATED_USER);
};

const selectAccount = () => {
  const currentAccounts = msalInstance.getAllAccounts();
  if (currentAccounts.length < 1) return;
  if (currentAccounts.length > 1) {
    const accounts = currentAccounts.filter(
      account =>
        account.homeAccountId.toUpperCase().includes('B2C_1A_SIGNIN') &&
        account.idTokenClaims.iss
          .toUpperCase()
          .includes(env.APP_MS_KNOWN_AUTHORITIES.split(',')[0]) &&
        account.idTokenClaims.aud === msalConfig.auth.clientId
    );

    if (accounts.length > 1) {
      if (accounts.every(account => account.localAccountId === accounts[0].localAccountId)) {
        setAccount(accounts[0]);
      } else {
        // Multiple users detected. Logout all to be safe.
        signOut();
      }
    } else if (accounts.length === 1) {
      setAccount(accounts[0]);
    }
  } else if (currentAccounts.length === 1) {
    setAccount(currentAccounts[0]);
  }
};

const handleResponse = async response => {
  if (response !== null) {
    const { state, account } = response;
    await setAccount(account);
    if (state) {
      // TODO: Hacer lo que quiera con el state
    }
  } else {
    selectAccount();
  }
};

const handlePolicyChange = policy => {
  if (policy === 'B2C_1A_PROFILEEDIT') {
    // eslint-disable-next-line no-console
    console.log('El perfil ha sido actualizado con éxito. Por favor inicie sesión de nuevo.');
  } else if (policy === 'B2C_1A_PASSWORDRESET') {
    signOut(`${env.APP_BASE_DOMAIN}${enums.SUCCESS_PASSWORD_RESET_URL}`);
  }
};

const getTokenRedirect = (forceRefresh = false) => {
  const account = getActiveAccount();
  if (!account) return null;

  // Providing an account in the token request is not required if there is an active account set
  const accessTokenRequest = {
    account,
    scopes,
    forceRefresh // Set this to "true" to skip a cached token and go to the server to get a new token
  };

  return msalInstance
    .acquireTokenSilent(accessTokenRequest)
    .then(({ idToken }) => idToken)
    .catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        // return msalInstance.acquireTokenRedirect(accessTokenRequest);
      } else {
        console.error(error);
      }
      return '';
    });
};

const signIn = state => {
  const loginRequest = {
    scopes
  };
  if (state) loginRequest.state = state;
  msalInstance.loginRedirect(loginRequest);
};

const passwordReset = () => {
  msalInstance.loginRedirect({
    authority: env.APP_MS_AUTHORITY_PASSWORDRESET,
    scopes
  });
};

const signOut = (route = `${env.APP_BASE_DOMAIN}#cerrar-sesion`) => {
  const request = { account: msalInstance.getActiveAccount() };
  if (route) request.postLogoutRedirectUri = route;
  // Get service worker version installed
  const serviceWorkerVersion = localStorage.getItem(enums.STORAGE_VERSION_SERVICE_WORKER);
  localStorage.clear();
  // Set service worker version installed
  localStorage.setItem(enums.STORAGE_VERSION_SERVICE_WORKER, serviceWorkerVersion);
  msalInstance.logoutRedirect(request);
};

// Acquires and access token and then passes it to the API call
const acquireToken = async (forceRefresh = false) => {
  if (!accessToken || forceRefresh) {
    return await getTokenRedirect(forceRefresh);
  } else {
    return accessToken;
  }
};

const ssoSilent = () => {
  const account = getActiveAccount();
  if (!account) {
    // events.emit(enums.EVT_AUTH_AUTHENTICATED_USER);
    return null;
  }

  // Providing an account in the token request is not required if there is an active account set
  msalInstance
    .ssoSilent({
      redirectUri: env.APP_TOOLS_BASE_URL,
      account
    })
    .then(async response => {
      await setAccount(response.account);
    })
    .catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        // Fallback to interaction when silent call fails
        // return msalInstance.acquireTokenRedirect(ssoSilentRequest);
        // Logout all to be safe.
        signOut();
      }
    });
};

const getActiveAccount = () => msalInstance.getActiveAccount();

const getIsAuthenticated = () => {
  const accounts = msalInstance.getAllAccounts();
  return accounts && accounts.length > 0;
};

const setLoadingAuth = state => {
  // store.commit("auth/setLoadingAuth", state);
};

export default {
  initialize,
  signIn,
  signOut,
  acquireToken,
  passwordReset,
  getActiveAccount,
  getIsAuthenticated
};
