import apiClient from '../helpers/apiClient';
import strapiClient from '../helpers/strapiClient';
import { getCommonsPage } from './graphql/commons.gql';
import { helpers } from '@pt/utilities';

export default {
  /* GET */

  getUserProfile: () => apiClient.get(`/profiling/userProfile`),

  getExploitations: () => apiClient.get('/cultivos/exploitations'),

  getExploitationById: id => apiClient.get(`/cultivos/exploitations/${id}`),

  getFarms: exploitation => apiClient.get(`/cultivos/exploitations/${exploitation}/farms`),

  getFarmById: (exploitation, farm) =>
    apiClient.get(`/cultivos/exploitations/${exploitation}/farms/${farm}`),

  getCropById: (exploitation, farm, crop) =>
    apiClient.get(`/cultivos/exploitations/${exploitation}/farms/${farm}/crops/${crop}`),

  getIrrigationPlans: (exploitation, farm, crop) =>
    apiClient.get(
      `/riegoyfertilizacion/exploitations/${exploitation}/farms/${farm}/crops/${crop}/irrigationplans`
    ),
  getFertilizationPlans: (exploitation, farm, crop) =>
    apiClient.get(
      `/riegoyfertilizacion/exploitations/${exploitation}/farms/${farm}/crops/${crop}/fertilizationplans`
    ),
  getFertilizationPrediction: (exploitation, farm, crop, plan) =>
    apiClient.get(
      `/riegoyfertilizacion/exploitations/${exploitation}/farms/${farm}/crops/${crop}/fertilizationplans/${plan}/fertilizationPrediction`
    ),
  getProvinces: () => apiClient.get('/cultivos/provinces'),

  getMunicipalities: province =>
    apiClient.get('/cultivos/municipality', {
      params: {
        provincia: province
      }
    }),

  getCrops: (exploitation, farm) =>
    apiClient.get(`/cultivos/exploitations/${exploitation}/farms/${farm}/crops?actuales=true`),

  getCropsType: (remains = false, plagues = false) => {
    let path = '/cultivos/cropsType';
    if (remains) path = `${path}?restosFertilizacion=true`;
    if (plagues) path = `${path}${remains ? '&' : '?'}plagues=true`;
    return apiClient.get(path);
  },

  getAgroCropsType: crop => apiClient.get(`/cultivos/cropsType/${crop}?agroanalysis=true`),

  getCropsClassification: () => apiClient.get('/cultivos/cropsClassification'),

  getCropsSystem: () => apiClient.get('/cultivos/cropsSystem'),

  getIrrigationsType: () => apiClient.get('/cultivos/irrigationsType'),

  getWeather: (latitud, longitud) =>
    apiClient.get(`/meteo/forecast?geoCode=${latitud},${longitud}&lang=es&units=m`),

  getStructureCoverType: () => apiClient.get('/riegoyfertilizacion/structureCoverType'),

  getWhitewashedType: () => apiClient.get('/riegoyfertilizacion/whitewashedType'),

  getGroundTexture: () => apiClient.get('/riegoyfertilizacion/groundTexture'),

  getOrganicAmendmentType: () => apiClient.get('/riegoyfertilizacion/organicAmendmentType'),

  getLimestoneAmendmentType: () => apiClient.get('/riegoyfertilizacion/limestoneAmendmentType'),

  getPhosphorousCompostType: () => apiClient.get('/riegoyfertilizacion/phosphorousCompostType'),

  getPotassiumCompostType: () => apiClient.get('/riegoyfertilizacion/potassiumCompostType'),

  getFertigationCompostType: () => apiClient.get('/riegoyfertilizacion/fertigationCompostType'),

  getDeepCompostType: () => apiClient.get('/riegoyfertilizacion/deepCompostType'),

  getCovertCompostType: () => apiClient.get('/riegoyfertilizacion/covertCompostType'),

  getAgroAnalysisCalculation: crop =>
    apiClient.get(`/agroanalisis/agroanalysisCalculation/${crop}`),

  getWaterAnalysis: (exploitation, farm) =>
    apiClient.get(`/cultivos/exploitations/${exploitation}/farms/${farm}/waterAnalysis`),

  getWaterAnalysisById: (exploitation, farm, analysis) =>
    apiClient.get(
      `/cultivos/exploitations/${exploitation}/farms/${farm}/waterAnalysis/${analysis}`
    ),

  getGroundAnalysis: (exploitation, farm) =>
    apiClient.get(`/cultivos/exploitations/${exploitation}/farms/${farm}/groundAnalysis`),

  getGroundAnalysisById: (exploitation, farm, analysis) =>
    apiClient.get(
      `/cultivos/exploitations/${exploitation}/farms/${farm}/groundAnalysis/${analysis}`
    ),

  getFinalizedCrops: (exploitation, farm) =>
    apiClient.get(`/cultivos/exploitations/${exploitation}/farms/${farm}/crops/resume`),

  getNotificationsList: () => apiClient.get('/notifications/list?readed=false'),

  getCommons: locale =>
    strapiClient
      .gqlQuery(getCommonsPage, { locale })
      .then(({ data: { common } }) => helpers.mapGraphQuery(common)),

  /* POST */

  postExploitation: exploitationValue => {
    return apiClient.post('/cultivos/exploitations', {
      nombre: exploitationValue
    });
  },

  postFarm: (exploitation, farm) =>
    apiClient.post(`/cultivos/exploitations/${exploitation}/farms`, farm),

  postCrop: (exploitation, farm, crop) =>
    apiClient.post(`/cultivos/exploitations/${exploitation}/farms/${farm}/crops`, crop),

  postIrrigationPlan: (exploitation, farm, crop, planValue) =>
    apiClient.post(
      `/riegoyfertilizacion/exploitations/${exploitation}/farms/${farm}/crops/${crop}/irrigationplans`,
      planValue
    ),

  postFertilizationPlan: (exploitation, farm, crop, planValue) =>
    apiClient.post(
      `/riegoyfertilizacion/exploitations/${exploitation}/farms/${farm}/crops/${crop}/fertilizationplans`,
      planValue
    ),

  postIrrigationPrediction: (exploitation, farm, crop, plan) =>
    apiClient.post(
      `/riegoyfertilizacion/exploitations/${exploitation}/farms/${farm}/crops/${crop}/irrigationplans/${plan}/irrigationPrediction`,
      {
        fechaMin: null,
        fechaMax: null
      }
    ),
  postAgroAnalysisCalculation: planValue =>
    apiClient.post('/agroanalisis/agroanalysisCalculation', planValue),

  postWaterAnalysis: (exploitation, farm, analysis) =>
    apiClient.post(`/cultivos/exploitations/${exploitation}/farms/${farm}/waterAnalysis`, analysis),

  postGroundAnalysis: (exploitation, farm, analysis) =>
    apiClient.post(
      `/cultivos/exploitations/${exploitation}/farms/${farm}/groundAnalysis`,
      analysis
    ),

  /* PUT */

  putExploitation: (exploitation, exploitationValue) =>
    apiClient.put(`cultivos/exploitations/${exploitation}`, {
      nombre: exploitationValue
    }),

  putFarm: (exploitation, farm, farmValue) =>
    apiClient.put(`/cultivos/exploitations/${exploitation}/farms/${farm}`, farmValue),

  putCrop: (exploitation, farm, crop, cropValue) =>
    apiClient.put(`/cultivos/exploitations/${exploitation}/farms/${farm}/crops/${crop}`, cropValue),

  putIrrigationPlan: (exploitation, farm, crop, plan, planValue) =>
    apiClient.put(
      `/riegoyfertilizacion/exploitations/${exploitation}/farms/${farm}/crops/${crop}/irrigationplans/${plan}`,
      planValue
    ),

  putFertilizationPlan: (exploitation, farm, crop, plan, planValue) =>
    apiClient.put(
      `/riegoyfertilizacion/exploitations/${exploitation}/farms/${farm}/crops/${crop}/fertilizationplans/${plan}`,
      planValue
    ),

  putWaterAnalysis: (exploitation, farm, analysisId, analysisData) =>
    apiClient.put(
      `/cultivos/exploitations/${exploitation}/farms/${farm}/waterAnalysis/${analysisId}`,
      analysisData
    ),

  putGroundAnalysis: (exploitation, farm, analysisId, analysisData) =>
    apiClient.put(
      `/cultivos/exploitations/${exploitation}/farms/${farm}/groundAnalysis/${analysisId}`,
      analysisData
    ),

  setReadedNotification: notificationId =>
    apiClient.put(`/notifications/setReaded/${notificationId}`, { is_readed: true }),

  setReadedNotificationAll: group =>
    apiClient.put(`notifications/setReaded/all`, { is_readed: true, ...group }),

  /* DELETE */

  deleteExploitation: exploitation => apiClient.delete(`/cultivos/exploitations/${exploitation}`),

  deleteFarm: (exploitation, farm) =>
    apiClient.delete(`/cultivos/exploitations/${exploitation}/farms/${farm}`),

  deleteCrop: (exploitation, farm, crop) => {
    return apiClient.delete(`/cultivos/exploitations/${exploitation}/farms/${farm}/crops/${crop}`);
  },

  deleteIrrigationPlan: (exploitation, farm, crop, plan) =>
    apiClient.delete(
      `/riegoyfertilizacion/exploitations/${exploitation}/farms/${farm}/crops/${crop}/irrigationplans/${plan}`
    ),

  deleteFertilizationPlan: (exploitation, farm, crop, plan) =>
    apiClient.delete(
      `/riegoyfertilizacion/exploitations/${exploitation}/farms/${farm}/crops/${crop}/fertilizationplans/${plan}`
    ),

  deleteWaterAnalysis: (exploitation, farm, analysis) =>
    apiClient.delete(
      `/cultivos/exploitations/${exploitation}/farms/${farm}/waterAnalysis/${analysis}`
    ),

  deleteGroundAnalysis: (exploitation, farm, analysis) =>
    apiClient.delete(
      `/cultivos/exploitations/${exploitation}/farms/${farm}/groundAnalysis/${analysis}`
    ),
  /* CANCEL */

  abortToken: () => apiClient.abort.token
};
