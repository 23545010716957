import axios from 'axios';
import { env } from '@pt/env-config';
import { print } from 'graphql/language/printer';

const httpStrapi = axios.create({
  baseURL: env.API_HOST_STRAPI_GRAPHQL
});

export default class strapiClient {
  static async gqlQuery(query, variables) {
    const { data } = await httpStrapi.post(env.API_GRAPHQL_QUERY_EXT, {
      query: print(query),
      variables
    });
    return data;
  }
}
