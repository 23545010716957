import axios from 'axios';
import auth from '../auth';
import { env } from '@pt/env-config';

const client = (config = {}) => {
  const client = axios.create(config);
  const { acquireToken } = auth;
  client.interceptors.request.use(
    async config => {
      const locale = document.querySelector('html').getAttribute('lang') || 'es';
      config.headers.common['Accept-Language'] = locale;
      const idToken = await acquireToken();
      if (idToken) config.headers.common.Authorization = idToken;
      return config;
    },
    error => {
      Promise.reject(error.response);
    }
  );
  client.interceptors.response.use(
    async response => {
      return Promise.resolve(response.data);
    },
    async error => {
      return Promise.reject(error.response);
    }
  );
  return client;
};

export default client({
  baseURL: env.API_HOST,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': env.API_OCP_APIM_SUBSCRIPTION_KEY,
    'Ocp-Apim-Trace': env.APP_ENVIRONMENT === 'development',
    withCredentials: true
  }
});
